import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import { findT }        from '@interness/web-core/src/components/_helpers';
import Carousel         from '@interness/web-core/src/components/media/Carousel/Carousel';
import Spacer           from '@interness/web-core/src/components/structure/Spacer/Spacer';
import ResponsiveIFrame from '@interness/web-core/src/components/structure/iFrame/iFrame';
import Overridable      from '@interness/web-core/src/components/Overridable/Overridable';

import BrandPageWrapper from '@interness/ecommerce-addon/src/components/BrandPageWrapper/BrandPageWrapper';

import PageLayout from '../page/base';

const SeikoBrandPageTemplate = props => {
  const page = props.data.directusBrands.brand;
  const metaData = props.data.allSitePlugin.nodes;
  const translations = findT(page.translations, props.pageContext.lang);
  const typeTranslations = findT(props.data.directusBrandTypes.translations, props.pageContext.lang);
  const headerImage = page.header_images[0].file.localFile.childImageSharp.fluid;
  const seoTitle = `${translations.seo_title ? translations.seo_title : page.display_name} ${typeTranslations.display_name}`;

  let hasEcom = metaData.filter(plugin => plugin.name === '@interness/ecommerce-addon');
  hasEcom = hasEcom.length === 1;


  if (props.data.directusProject.custom_values) {
    const hasIframe = props.data.directusProject.custom_values.has_seiko_iframe;

    if (hasIframe) {
      return (
        <PageLayout page={page} translations={translations} seoTitle={seoTitle} showCallToAction showSubnavigation
                    headerImage={headerImage}
                    type={page.type.type}>
          <iframe id="seiko"
                  style={{
                    width: '100%',
                    height: '800px',
                    border: 'none',
                  }}
                  loading="lazy"
                  src={'https://seiko.interaktive-beratung.de'}
                  title={'Seiko'}
          />
        </PageLayout>
      )
    }
  }


  return (
    <PageLayout page={page} translations={translations} seoTitle={seoTitle} showCallToAction showSubnavigation
                headerImage={headerImage}
                type={page.type.type}>
      {page.gallery_images.length > 0 &&
        <>
          <div>
            <Carousel>
              {page.gallery_images.map(image => (
                <figure key={image.file.localFile.name}>
                  <Img fluid={image.file.localFile.childImageSharp.fluid} alt={image.file.localFile.name}/>
                  {image.file.description &&
                    <figcaption style={{ backgroundColor: '#fff', paddingTop: '20px' }}
                                dangerouslySetInnerHTML={{ __html: image.file.description }}/>}
                </figure>
              ))}
            </Carousel>
          </div>
          <Spacer/>
        </>}
      {page.youtube_video_id &&
        <>
          <ResponsiveIFrame src={`https://www.youtube-nocookie.com/embed/${page.youtube_video_id}?rel=0&amp;controls=0`}
                            title={`YoutubeVideo`} type={'youtube'}/>
          <Spacer/>
        </>}
      {page.vimeo_video_id &&
        <>
          <ResponsiveIFrame maxHeight={56.25} src={`https://player.vimeo.com/video/${page.vimeo_video_id}?dnt=1`}
                            title={`VimeoVideo`} type={'vimeo'}/>
          <Spacer/>
        </>}
      <div>
        {translations.description &&
          <>
            <h3>{page.display_name}</h3>
            <Overridable fieldName="translations.description" collection="brand" itemId={page.directus_id}
                         type="html_override"
                         render={override => {
                           return <div style={{ textAlign: 'justify' }}
                                       dangerouslySetInnerHTML={{ __html: override ? override : translations.description }}/>;
                         }}/>
          </>
        }
      </div>
      {hasEcom && <div>
        <BrandPageWrapper category={typeTranslations.slug} title={`${page.display_name} ${typeTranslations.slug}`}
                          manufacturer={page.slug}/>
      </div>}
    </PageLayout>
  )
};

export default SeikoBrandPageTemplate;

export const query = graphql`
    query($slug: String = "/undefined", $type: String = "undefined") {
        directusBrands(brand: {slug: {eq: $slug}, type: {type: {eq: $type}}}) {
            ...BrandsCore
            ...BrandHeaderImages
            ...BrandGalleryImages
        }
        directusBrandTypes(type: {eq: $type}) {
            type
            translations {
                display_name
                slug
                language
            }
        }
        allSitePlugin(filter: {name: {regex: "/@interness/"}}) {
            nodes {
                version
                name
            }
        }
        directusProject(id: {ne: "dummy"}) {
            custom_values {
                has_seiko_iframe
            }
        }
    }
`;